import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"


const HeroImage = ({image}) => (
  <div className="heroImage p40 mt80 m-mt20 max-550 ma">
    <div className="ratio-2-1 pos-rel">
      <div className="overflow-hidden bg-image"> 
        <div className="zoom--in bg-image" data-sal>
          <GatsbyImage className='bg-image' image={image.gatsbyImageData} alt='Hero Image' /> 
        </div>
      </div>
    </div>
    
  </div> 
) 
export default HeroImage
