import * as React from "react"
import Layout from "../components/layout"
import ImageText from "../components/featuredBanner"
import Paragraph from "../components/paragraph"
import Image from "../components/image"
import SEO from "../components/seo"
import { graphql } from 'gatsby'

class AboutPage extends React.Component {
	componentDidMount() {

	}
	render() {
		const about = this.props.data.about
	    return (
		  <Layout>
		   	<SEO title="Meet Grace" />
		   	<ImageText image={about.aboutImage} title='Meet Grace' />
		   	<Paragraph paragraphs={about.paragraphs} />
		   	<Image image={about.officeImage} />
		  </Layout>
		)
	}
}

export default AboutPage

export const query = graphql`
query AboutQuery {
  about:datoCmsAbout {
    paragraphs {
      content
    }
    aboutImage {
    	gatsbyImageData(placeholder:NONE)
    }
    officeImage {
    	gatsbyImageData(placeholder:NONE)
    }
  } 
}
`