import * as React from "react"
import ConditionallyRender from "./conditionallyRender"
const Paragraph = ({ paragraphs }) => {
  return(
  <div className="max-550 h3 ma text-center fade--in m-p20 mt80 m-mt20" data-sal>
  
    {paragraphs.map(({ content },index) => (
      <div key={index}>
      { content }
      <ConditionallyRender when={(index !== paragraphs.length - 1  )}> <br/>  <br/></ConditionallyRender>

      </div>
    ))}

  </div>)
}
export default Paragraph
